import { defineStore } from 'pinia';
import { ref } from 'vue';

import type SnackbarMessage from "~/interfases/snackbar/snackbar-message";

export const useSnackbarStore = defineStore('snackbar-store', () => {
    const message = ref<SnackbarMessage | null>(null);

    const setMessage = (msg: SnackbarMessage) => {
        message.value = msg;
    }

    const showMessage = () => {
        if (message.value) {
            message.value.show = true;

            setTimeout(() => {
                message.value = null;
            }, 3000);
        }
    }

    return {
        message,
        setMessage,
        showMessage
    }
})
